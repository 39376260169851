html, body {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
}

.submitted-main-container {
    padding: 5% 15%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    box-sizing: border-box;
}

.submitted-box {
    display: flex;
    align-items: center;
    border-left: 4px solid #47BC8A;
    background: #F9F9F9;
    padding: 1rem;
    border-radius: 3px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    min-width: 300px;
    max-width: 100%;
    box-sizing: border-box;
    text-align: center;
    flex-wrap: wrap;
}

.submitted-box p {
    margin: 0;
    color: #333;
    font-size: 16px;
    font-family: Mulish, sans-serif;
    font-weight: 600;
    overflow: hidden;
    text-overflow: ellipsis;
    flex: 1;
    padding-left: 0.5rem; 
    word-wrap: break-word;
}

.ellipse {
    color: #26a83b;
    font-size: 24px;
    margin-right: 10px;
    display: flex;
    align-items: center;
}

@media (max-width: 768px) {
    .submitted-main-container {
        padding: 2% 10%;
    }
    .submitted-box {
        padding: 0.8rem;
    }
    .submitted-box p {
        font-size: 14px;
    }
    .ellipse {
        font-size: 20px;
    }
}

@media (max-width: 480px) {
    .submitted-main-container {
        padding: 1% 5%;
    }
    .submitted-box {
        padding: 0.5rem;
    }
    .submitted-box p {
        font-size: 12px;
    }
    .ellipse {
        font-size: 18px;
    }
}