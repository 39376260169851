.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: #333333;
  font-family: 'Mulish', sans-serif;
  font-style: normal;
  padding: 2% 4%;
  box-sizing: border-box;
  width: 100%;
  min-height: 100vh;
  overflow: hidden;
}

.placeholder {
  text-align: left;
  color: #999;
  font-size: 16px;
}

.content {
  text-align: left;
  width: 100%;
  box-sizing: border-box;
}

.heading {
  color: #333;
  margin-top: 1rem;
}

.text {
  font-size: 1.1rem;
}

.buttons {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 10px;
  margin-top: 1rem;
  width: 100%;
  box-sizing: border-box;
}

.button {
  padding: 8px 15px;
  border: none;
  border-radius: 6px;
  color: #fff;
  font-size: 0.875rem;
  cursor: pointer;
  transition: background-color 0.3s;
  text-align: center;
  min-width: 120px;
  max-width: 200px;
  margin: 5px;
  box-sizing: border-box;
}

.button:hover {
  opacity: 0.9;
}

.button-approve {
  background-color: green;
}

.button-reject {
  background-color: red;
}

.button-approve:disabled,
.button-reject:disabled {
  background-color: #dddddd;
  cursor: not-allowed;
}

.Checkbox-wrapper {
  margin-top: 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.Checkbox {
  width: 30px;
  height: 18px;
  font-family: 'Mulish', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 0.875rem;
  color: #333333;
}

.CheckboxLabel {
  font-family: 'Mulish', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 0.875rem;
  color: #333333;
  margin-top: .5rem;
}

.product-information {
  color: rgb(15, 50, 103);
  font-size: 0.875rem;
  margin-bottom: 1rem;
  width: 100%;
  box-sizing: border-box;
  word-break: break-word;
  overflow-wrap: break-word;
}

.product-information-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
}

.product-details-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 20px; 
  padding: 5px 0px; 
}

.product-card {
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  flex-grow: 1;
}
  

.product-details-heading {
  color: rgb(15, 50, 103);
  font-size: 1.25rem;
  font-weight: bold;
  display: flex;
  align-items: center;
}

.more-text {
  color: rgb(15, 50, 103);
  font-size: 16px;
  font-style: italic;
}

.product-information .key-value {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 0.5rem;
}

.product-information .key-value .key {
  margin-right: 0.5rem;
  white-space: nowrap;
  font-weight: bold;
  min-width: 150px;
}

.product-information .key-value .value {
  flex: 1;
  word-break: break-word;
  overflow-wrap: break-word;
  display: inline-block;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 1rem;
  overflow-x: auto;
  table-layout: fixed;
}

th, td {
  padding: 0.75rem;
  text-align: left;
  word-wrap: break-word;
  white-space: normal;
}

.customer-info-container {
  margin: 1.5rem 0;
}

.table-heading {
  display: flex;
  align-items: center;
  font-size: 1.25rem;
  color: #444;
  margin-bottom: 0.5rem;
}

.comments {
  height: 60px;
  width: 100%;
  border-radius: 4px;
}

.person-icon {
  vertical-align: middle;
  margin-right: 8px;
  font-size: 1.6rem !important;
}

.collapse-icon {
  color: blue !important;
  float: right;
}

.product-field-heading{
  font-weight: 300;
}

.product-icon {
  vertical-align: middle;
  margin-right: 8px;
  font-size: 1.6rem !important;
}

.product-title{
  font-weight: 500px;
}

.customer-card {
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  transition: box-shadow 0.3s ease;
}

.product-field-values{
  font-weight: 790;
}

.customer-heading {
  color: rgb(15, 50, 103);
  font-size: 1.25rem;
  font-weight: bold;
  display: flex;
  align-items: center;
}

.customer-title {
  font-weight: bold;
  font-size: 1.1rem;
  color: #333;
}

.customer-address {
  font-size: 0.9rem;
  color: #666;
}

@media (max-width: 768px) {
  .text {
    font-size: 1rem;
  }

  .buttons {
    flex-direction: column;
    gap: 0.5rem;
    width: 100%;
    margin-bottom: 50px;
  }

  .button {
    width: 100%;
    min-width: unset;
    max-width: unset;
  }

  .product-information {
    font-size: 0.75rem;
    padding: 0 5px;
  }

  .product-card {
    flex: 1 1 100%;
  }

  table {
    font-size: 0.875rem;
  }
}

@media (max-width: 480px) {
  .text {
    font-size: 0.9rem;
  }

  .buttons {
    flex-direction: column;
    gap: 0.5rem;
    width: 100%;
    margin-bottom: 60px;
  }

  .button {
    width: 100%;
    min-width: unset;
    max-width: unset;
  }

  table {
    font-size: 0.75rem;
  }
}