html, body {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
}
 
.unAuthorized-main-container {
    padding: 5% 15%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    box-sizing: border-box;
}
 
.unAuthorized-box {
    display: flex;
    align-items: center;
    border-left: 4px solid red;
    background: #F9F9F9;
    padding: 1rem;
    border-radius: 3px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    min-width: 300px;
    max-width: 100%;
    box-sizing: border-box;
    text-align: left;
    overflow: hidden;
    flex-wrap: wrap;
}
 
.unAuthorized-box p {
    margin: 0;
    color: #333;
    font-size: 16px;
    font-family: Mulish, sans-serif;
    font-weight: 600;
    overflow: hidden;
    text-overflow: ellipsis;
    flex: 1;
    padding-left: 0.5rem;
    word-wrap: break-word;
}
 
.lockOutlined {
    color: red;
    font-size: 40px;
    margin-right: 10px;
    display: flex;
    align-items: center;
}
 
@media (max-width: 768px) {
    .unAuthorized-main-container {
        padding: 3% 10%;
    }
    .unAuthorized-box {
        padding: 0.8rem;
    }
    .unAuthorized-box p {
        font-size: 14px;
    }
    .lockOutlined {
        font-size: 30px;
    }
}
 
@media (max-width: 480px) {
    .unAuthorized-main-container {
        padding: 2% 5%;
    }
    .unAuthorized-box {
        padding: 0.5rem;
    }
    .unAuthorized-box p {
        font-size: 12px;
    }
    .lockOutlined {
        font-size: 24px;
    }
}